import { useQuery } from "react-query"
import { Patient, Practitioner } from "fhir"

import { useClient } from "api"
import { useAuth } from "security"

const useLinkedUserResource = () => {
  const { read } = useClient()
  const { user } = useAuth()

  const { data, isLoading, error } = useQuery(
    ["user-linked-resource", user?.linkedResource],
    async () => {
      if (!user) {
        throw new Error("Not found", { cause: { name: "404", message: "User not found" } })
      }

      const { id, resourceType } = user.linkedResource ?? {}

      if (!id || !resourceType) {
        throw new Error("Not found", { cause: { name: "404", message: "User has not liked resource" } })
      }

      const resource = await read<Practitioner | Patient>(resourceType, id)

      if (!resource) {
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `Not found a practitioner or patient linked to logged in user ${user.name} with email ${user.email}`,
          },
        })
      }

      return resource
    },
    { suspense: true },
  )

  return { userLinkedResource: data, isLoading, error }
}

export { useLinkedUserResource }
