import { Reference } from "fhir"
import { useMsal } from "@azure/msal-react"
import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { createContext, useMemo, useContext, ReactNode, useCallback, useEffect, useState } from "react"

import { LoadingView } from "commons"
import { loginRequest } from "authConfig"
import { AuthError, CustomError } from "errors"
import { apm } from "logger"

const AuthContext = createContext<State | undefined>(undefined)
AuthContext.displayName = "AuthContext"
const isDevelopment = process.env.NODE_ENV === "development"
const userMock: User = {
  email: "johndoe@divineslns.com",
  name: "John Doe",
  token: `Basic ${process.env.REACT_APP_SERVER_ACCESS_TOKEN}`,
  linkedResource: {
    resourceType: "Practitioner",
    id: "pract1",
  },
}

const RENEW_TOKEN_TIMEOUT = 2400000 // Wait 40 minutes then refresh access token

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { instance, inProgress } = useMsal()
  const [isLoading, setIsloading] = useState(!isDevelopment)
  const [user, setUser] = useState<User | undefined>(isDevelopment ? userMock : undefined)
  const [error, setError] = useState<Error | undefined>()

  const initializeUser = useCallback(
    (response: AuthenticationResult) => {
      instance.setActiveAccount(instance.getAccountByHomeId(response.account?.homeAccountId!))

      const name = response.account?.name ?? "unspecified"
      const email = (response.idTokenClaims as { email: string })?.email ?? "unspecified"
      const token = `${response.tokenType} ${response.accessToken}`

      if (!(response.idTokenClaims as IdTokenClaims)["resource/id"]) {
        setError(
          new Error("Authentication error", {
            cause: { name: "500", message: `No resource linked to user ${name}` },
          }),
        )
      } else {
        const linkedResource: Reference = {
          resourceType: (response.idTokenClaims as IdTokenClaims)["resource/type"],
          id: (response.idTokenClaims as IdTokenClaims)["resource/id"],
        }

        apm.setUserContext({ id: linkedResource.id, username: name, email })
        setUser({ name, email, token, linkedResource })
      }

      setIsloading(false)
    },
    [instance],
  )

  const tryRenewAccessToken = useCallback(() => {
    const account = instance.getActiveAccount()

    if (account) {
      const request = {
        ...loginRequest,
        account,
      }

      // Silently acquires an access token which is then attached to a request for aidbox data
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          initializeUser(response)

          setTimeout(() => tryRenewAccessToken(), RENEW_TOKEN_TIMEOUT)
        })
        .catch((error) => {
          apm.captureError(error)

          if (error.errorMessage.includes("AADB2C90077") || error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(request)
          } else {
            setIsloading(false)
            setError(new Error(error.errorCode, { cause: { name: error.name, message: error.errorMessage } }))
          }
        })
    } else {
      if (inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest)
      }
    }
  }, [instance, initializeUser, inProgress])

  useEffect(() => {
    if (!isDevelopment) {
      instance
        .handleRedirectPromise()
        .then(async (response) => {
          if (response && response.account) {
            initializeUser(response)
            setTimeout(() => tryRenewAccessToken(), RENEW_TOKEN_TIMEOUT)
          } else {
            const account = instance.getActiveAccount()

            if (!account) {
              if (inProgress === InteractionStatus.None) {
                await instance.loginRedirect(loginRequest)
              }
            } else {
              tryRenewAccessToken()
            }
          }
        })
        .catch((error) => {
          setIsloading(false)
          apm.captureError(error)

          setError(
            new Error("Authentication error", {
              cause: { ...error },
            }),
          )
        })
    }
  }, [inProgress, initializeUser, instance, tryRenewAccessToken])

  const logout = useCallback(() => {
    const account = instance.getActiveAccount()

    if (account) {
      const logoutRequest = {
        account: instance.getAccountByHomeId(account.homeAccountId),
        postLogoutRedirectUri: "/",
      }
      instance.logoutRedirect(logoutRequest)
    }
  }, [instance])

  const value = useMemo(
    () => ({
      user,
      logout,
    }),
    [user, logout],
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    return <AuthError error={error as CustomError} logout={logout} />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider")
  }

  return context
}

type IdTokenClaims = {
  "resource/id": string
  "resource/type": string
}

type State = {
  user?: User
  logout(): void
}

type User = {
  email: string
  name: string
  token: string
  linkedResource: Reference
}

type Props = {
  children: ReactNode
}

export { AuthProvider, useAuth }
