import { useClient } from "api"
import { useQuery } from "react-query"

import { Patient, Questionnaire, QuestionnaireResponse } from "fhir"
import { getOperation } from "lib/compare-operator"
import { ItemEnableWhen, OperatorType, QuestionnaireItem, ResponseItem, ResponseItemAnswer } from "type-alias"

import { FormItem, isPatient, isQuestionnaire, isQuestionnaireResponse, WatchMap } from "../types"
import { useResponseParam } from "./useResponseParams"

const useQuestionnaireResponse = () => {
  const { search } = useClient()
  const { id: idParam, isReadOnly } = useResponseParam()

  if (!idParam) {
    throw new Error("Invalid response id")
  }

  const { data, isLoading, isError } = useQuery(
    "response",
    async () => {
      const { entry } = await search(
        "QuestionnaireResponse",
        new URLSearchParams(`_id=${idParam}&_include=questionnaire:Questionnaire, subject`),
      )

      const { questionnaire, response, patient } =
        entry?.reduce<ResponseResult>((result, { resource }) => {
          if (isQuestionnaire(resource)) {
            return { ...result, questionnaire: resource }
          }

          if (isQuestionnaireResponse(resource)) {
            return { ...result, response: resource }
          }

          if (resource && isPatient(resource)) {
            return { ...result, patient: resource }
          }

          return result
        }, {}) ?? {}

      if (!questionnaire || !response) {
        throw new Error("Invalid questionnaire or response")
      }

      const { formData, answers, total } = getFormData(questionnaire, response)

      return { questionnaire, response, patient, formData, answers, total }
    },
    { suspense: true },
  )

  if (isError) {
    throw new Error("The response you are trying to access is not available")
  }

  const { response } = data ?? {}
  const isCompleted = response?.status === "completed"

  return {
    data,
    isReadOnly: isReadOnly || isCompleted,
    isLoading,
  }
}

const getFormData = (questionnaire: Questionnaire, response: QuestionnaireResponse) => {
  let total = 0
  let answers = 0

  const conditionalMap = getConditionalMap(questionnaire.item ?? [], response.item ?? [])
  const valueSets: string[] = []

  const applyBehavior = (conditions: ItemEnableWhen[], enableBehavior?: string, valueMap?: WatchMap) => {
    const compare = ({ question, answer, operator }: ItemEnableWhen) => {
      const operation = getOperation(operator as OperatorType)
      const { name, value: defaultValue } = conditionalMap[question] ?? {}

      const value = valueMap ? valueMap[name] : defaultValue

      return !!value && !!answer && operation !== undefined && operation(Array.isArray(value) ? value : [value], answer)
    }

    return enableBehavior === "all" ? conditions.every(compare) : conditions.some(compare)
  }

  const getFormItem = (qItem: QuestionnaireItem[], rItem: ResponseItem[], isDisabledGroup?: boolean): FormItem[] => {
    const record = rItem.reduce(
      (map, item) => (item && item.linkId ? { ...map, [item.linkId]: [...(map[item.linkId] ?? []), item] } : map),
      {} as Record<string, ResponseItem[]>,
    )

    return qItem.reduce(
      (
        result,
        { type, linkId, text, initial, repeats, enableWhen, enableBehavior, answerOption, answerValueSet, item },
      ) => {
        if (!linkId || !type) {
          return result
        }

        const responses = record[linkId]
        const repeatsAnswer = repeats && responses?.length > 1 ? responses.slice(1) : undefined

        const { value } = responses?.[0]?.answer?.[0] ?? {}
        const { Coding, Quantity, boolean, date, dateTime, decimal, integer, string, time, uri } = value ?? {}

        const isDisplay = type === "group" || type === "display"
        const hasAnswer =
          !isDisplay &&
          (boolean !== undefined ||
            !!(Coding?.code ?? Quantity?.value ?? date ?? dateTime ?? decimal ?? integer ?? string ?? time ?? uri))

        const isDisabled = enableWhen && !applyBehavior(enableWhen, enableBehavior)

        if (!isDisabledGroup) {
          if (!isDisabled || !enableWhen) {
            total += !isDisplay ? 1 : 0
            answers += hasAnswer ? 1 : 0
          }
        }

        if (answerValueSet) {
          valueSets.push(answerValueSet)
        }

        const disabledGroup = type === "group" && isDisabled

        return [
          ...result,
          {
            linkId,
            type,
            repeats,
            text,
            initial,
            defaultValue: responses?.[0]?.answer ?? [],
            options: answerOption,
            valueSet: answerValueSet,
            item: item ? getFormItem(item, record[linkId]?.[0]?.item ?? [], disabledGroup) : undefined,

            conditional: enableWhen
              ? {
                  name: enableWhen.map(({ question }) => conditionalMap[question]?.name),
                  initialValue: enableWhen.reduce((acc, { question }) => {
                    const { name, value } = conditionalMap[question]

                    return { ...acc, [name]: value }
                  }, {} as WatchMap),
                  applyBehavior: (valueMap: WatchMap) => applyBehavior(enableWhen, enableBehavior, valueMap),
                }
              : undefined,

            repeatingItems: repeatsAnswer?.map(({ answer, item: repeatItem }) => ({
              linkId,
              type,
              text,
              initial,
              defaultValue: answer,
              options: answerOption,
              valueSet: answerValueSet,
              item: item ? getFormItem(item, repeatItem ?? []) : undefined,
            })),
          },
        ]
      },
      [] as FormItem[],
    )
  }

  const formData = getFormItem(questionnaire.item ?? [], response.item ?? [])

  return { formData, valueSets, answers, total }
}

const getConditionalMap = (
  item: QuestionnaireItem[],
  responseItem: ResponseItem[],
  name = "item",
  map: ConditionalMap = {},
): ConditionalMap => {
  const answers = responseItem.reduce(
    (map, { linkId, answer }) => (linkId && answer ? { ...map, [linkId]: answer } : map),
    {} as Record<string, ResponseItemAnswer[]>,
  )

  return item.reduce((acc, { linkId, repeats, item }, index) => {
    const path = `${name}.${index}`
    const result = item
      ? { ...acc, ...getConditionalMap(item, responseItem[index]?.item ?? [], `${path}.item`, map) }
      : acc

    return linkId && !(linkId in acc)
      ? {
          ...result,
          [linkId]: {
            name: `${path}.answer${!repeats ? ".0" : ""}`,
            value: repeats ? answers[linkId] : answers[linkId]?.[0],
          },
        }
      : result
  }, map)
}

type ConditionalMap = Record<
  string,
  {
    name: string
    value?: ResponseItemAnswer | ResponseItemAnswer[]
  }
>

type ResponseResult = {
  questionnaire?: Questionnaire
  response?: QuestionnaireResponse
  patient?: Patient
}

export { useQuestionnaireResponse, getFormData }
