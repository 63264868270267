import { Bundle, ResourceObject } from "fhir"
import { useCallback } from "react"

import { useAuth } from "security"

import {
  read as clientRead,
  search as clientSearch,
  create as clientCreate,
  update as clientUpdate,
  patch as clientPatch,
  remove as clientRemove,
  transaction as clientTransaction,
} from "../client"

const useClient = () => {
  const { user } = useAuth()
  const token = user?.token ?? ""

  const read = useCallback(
    <T extends ResourceObject>(endpoint: string, id: string, filters?: URLSearchParams, operation?: string) =>
      clientRead<T>(endpoint, id, token, filters, operation),
    [token],
  )

  const search = useCallback(
    (endpoint: string, filters: URLSearchParams, operation?: string) =>
      clientSearch(endpoint, filters, token, operation),
    [token],
  )

  const create = useCallback(
    <T extends ResourceObject>(endpoint: string, resource: T) => clientCreate(endpoint, resource, token),
    [token],
  )

  const update = useCallback(
    <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
      clientUpdate(endpoint, id, resource, token),
    [token],
  )

  const patch = useCallback(
    <T extends ResourceObject>(endpoint: string, id: string, resource: T, etag?: string) =>
      clientPatch(endpoint, id, resource, token, etag),
    [token],
  )

  const remove = useCallback(
    <T extends ResourceObject>(endpoint: string, id: string) => clientRemove<T>(endpoint, id, token),
    [token],
  )

  const transaction = useCallback((bundle: Bundle) => clientTransaction(bundle, token), [token])

  return { read, search, create, update, patch, remove, transaction }
}

export { useClient }
