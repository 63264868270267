import { Bundle, isOperationOutcome, ResourceObject, isResourceObject } from "fhir"

const apiUrl = window.REACT_APP_FHIR_SERVER

const client = async (endpoint: string, { token, method, body, headers: customHeaders, ...customConfig }: Config) => {
  const config = {
    method,
    body,
    headers: {
      Authorization: token,
      ...(body ? { "Content-Type": "application/json" } : {}),
      ...customHeaders,
    },
    ...customConfig,
  }

  const response = await fetch(`${apiUrl}/${endpoint}`, config)

  const data = await response.json()

  if (response.ok) {
    return data
  }

  switch (response.status) {
    case 401:
      throw new Error("Unauthorized", { cause: { name: "401", message: "Unauthorized" } })
    case 403:
      throw new Error("Forbidden", { cause: { name: "403", message: "Forbidden" } })
    default:
      if (isOperationOutcome(data)) {
        const message = data.issue?.[0].diagnostics ?? "Something went wrong"
        throw new Error("Internal server error", { cause: { name: "500", message } })
      }

      throw new Error("Not supported", {
        cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
      })
  }
}

const read = async <T extends ResourceObject>(
  endpoint: string,
  id: string,
  token: string,
  filters?: URLSearchParams,
  operation?: string,
  signal?: AbortSignal,
) => {
  const data = await client(`${endpoint}/${id}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`, {
    token,
    method: "GET",
    signal,
  })

  return data as T
}

const search = async (
  endpoint: string,
  filters: URLSearchParams,
  token: string,
  operation?: string,
  signal?: AbortSignal,
) => {
  const data = await client(`${endpoint}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`, {
    token,
    method: "GET",
    signal,
  })

  return data as Bundle
}

const create = async <T extends ResourceObject>(endpoint: string, resource: T, token: string, signal?: AbortSignal) => {
  const data = await client(`${endpoint}`, { token, method: "POST", body: JSON.stringify(resource), signal })

  return data as T
}

const update = async <T extends ResourceObject>(
  endpoint: string,
  id: string,
  resource: T,
  token: string,
  signal?: AbortSignal,
  etag?: string,
) => {
  const etagHeader = etag ?? resource.meta?.versionId

  const data = await client(`${endpoint}/${id}`, {
    token,
    method: "PUT",
    body: JSON.stringify(resource),
    headers: {
      "Content-Type": "application/json",
      ...(etagHeader ? { "If-Match": etagHeader } : {}),
    },
    signal,
  })

  return data as T
}

const patch = async <T extends ResourceObject>(
  endpoint: string,
  id: string,
  resource: Partial<T>,
  token: string,
  etag?: string,
  signal?: AbortSignal,
) => {
  const data = await client(`${endpoint}/${id}`, {
    token,
    method: "PATCH",
    body: JSON.stringify(resource),
    headers: {
      "Content-Type": "application/json",
      ...(etag ? { "If-Match": etag } : {}),
    },
    signal,
  })

  return data as T
}

const remove = async <T extends ResourceObject>(endpoint: string, id: string, token: string, signal?: AbortSignal) => {
  const data = await client(`${endpoint}/${id}`, { token, method: "DELETE", signal })

  return data as T
}

const transaction = async <T extends ResourceObject>(bundle: Bundle, token: string, signal?: AbortSignal) => {
  const body = {
    ...bundle,
    entry: bundle.entry?.map((entry) =>
      (entry.request?.method === "PUT" || entry.request?.method === "PATCH") &&
      isResourceObject(entry.resource) &&
      entry.resource.meta?.versionId
        ? { ...entry, request: { ...entry.request, ifMatch: entry.resource.meta?.versionId } }
        : entry,
    ),
  }

  const data = await client("", { token, method: "POST", body: JSON.stringify(body), signal })

  return data as T
}

type Config = { token: string } & RequestInit

type LookupParamsKeys = "by" | "sort" | "q" | "limit" | "count"

export type LookupParameters = Record<LookupParamsKeys, string>

export { read, search, create, update, patch, remove, transaction }
